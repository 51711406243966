/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'validar/:hash',
    loadChildren: () => import('./pages/validate/validate.module').then( m => m.ValidatePageModule)
  },
  {
    path: 'aceite/:digitalAcceptanceToken',
    loadChildren: () => import('./pages/aceite/aceite.module').then( m => m.AceitePageModule)
  },
  {
    path: 'termos/:id',
    loadChildren: () => import('./pages/termos/termos.module').then( m => m.TermosPageModule)
  },
  {
    path: 'send-code-digital-acceptance',
    loadChildren: () => import('./pages/token-validation-digital-acceptance/token-validation-digital-acceptance.module').then( m => m.TokenValidationDigitalAcceptancePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
