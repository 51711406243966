/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SettingsService } from 'src/service/settings.service';
import { HttpClientModule } from '@angular/common/http';
import { CodeInputModule } from 'angular-code-input';



export function initApp(settingService: SettingsService) {
  return () => settingService.initApp();
}


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CodeInputModule
    
    ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [SettingsService]
    },
    CodeInputModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
