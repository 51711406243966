import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Settings } from './settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  public settings = new Settings();

  constructor(public http: HttpClient) {}

  initApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get('assets/settings.json')
        .toPromise()
        .then(async (response: Settings) => {
          this.settings = response;
          resolve('');
        })
        .catch((err) => {
          resolve('');
        });
    });
  }

  getIPAddress(){
    return this.http.options(this.settings.apiUrl.concat('core/utils/myip/'));
  }

  createHttpParams(params: any): HttpParams {
    if(params){
      let httpParams: HttpParams = new HttpParams();
      Object.keys(params).forEach((param) => {
        if(params[param]) {
          httpParams = httpParams.set(param, params[param]);
        }
        if(params[param] === false) {
          httpParams = httpParams.set(param, params[param]);
        }
      });
      return httpParams;
    }
  }

}
